<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    persistent
  >
    <v-card>
      <v-card-title class="form-modal-header">
        <v-btn color="white" text @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <span class="text-h5 white--text">Atualizar Registro</span>
        <v-spacer></v-spacer>
        <v-btn color="white" text @click="update"> Atualizar </v-btn>
      </v-card-title>
      <v-card-text class="mt-12">
        <v-container>
          <v-row>
            <v-col cols="12" md="6" class="col-field">
              <v-toolbar elevation="0" style="background: none">
                <v-toolbar-title class="font-weight-bold">
                  Selecione um Talhão
                </v-toolbar-title>
              </v-toolbar>

              <v-row>
                <v-col cols="8">
                  <simple-select
                    :items="fields"
                    :disabled="true"
                    v-model="payload.field_id"
                    itemText="name"
                    itemValue="id"
                    height="auto"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>

        <v-container>
          <v-row>
            <v-col cols="12" md="8" class="col-field">
              <v-toolbar elevation="0" style="background: none">
                <v-toolbar-title class="font-weight-bold">
                  Índices de Fertilidade
                </v-toolbar-title>
              </v-toolbar>

              <v-container class="custom-container">
                <v-row>
                  <v-col
                    cols="12"
                    md="4"
                    v-for="fertilityIndex in fertilityIndexes.indexes"
                    :key="fertilityIndex.id"
                  >
                    <custom-label :label="fertilityIndex.name" />
                    <simple-select
                      v-model="selectedIndex[fertilityIndex.id]"
                      :items="grades"
                      placeholder="Selecione uma opção"
                      :itemValue="fertilityIndex.id.toString()"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import FertilityRecords from "@/domain/records/fertility-records/fertility-records";
import SimpleSelect from "@/components/inputs/SimpleSelect";
import CustomLabel from "@/components/texts/CustomLabel";

export default {
  name: "FertilityRecordFieldEdit",
  components: { SimpleSelect, CustomLabel },
  props: {
    fertilityIndexes: {
      type: Object,
      default: () => {}
    },

    dialog: {
      type: Boolean,
      default: false
    },

    fieldSelected: {
      type: Number
    }
  },

  data() {
    return {
      payload: {},
      fields: [],
      fieldId: '',
      harvest: '',
      fertilityRecordService: null,
      grades: ['N/A', '6.0', '6.5', '7.0', '7.5', '8.0', '8.5', '9.0', '9.5', '10.0'],
      selectedIndex: []
    }
  },

  methods: {
    toggleScrollBar(param) {
      let html = document.querySelector('html');
      html.setAttribute("style", `overflow: ${param};`);
    },

    close() {
      this.toggleScrollBar('auto');
      this.$emit('closeDialog');
    },

    update() {
      const loader = this.$loading.show();

      this.payload.harvest     = this.fertilityIndexes.harvest;
      this.payload.producer_id = this.fertilityIndexes.producer_id;

      this.selectedIndex = this.selectedIndex.map(item => {
        if (item == 'N/A') item = null;
        return item;
      });

      Object.assign(this.payload.indexes, this.selectedIndex);

      this.fertilityRecordService
        .edit(this.payload)
        .then(() => {
          loader.hide();
          this.close();

          this.$toasted.show("Registro de fertilidade atualizado com sucesso.", {
            type: "success",
          });
        })
        .catch(({response}) => {
          loader.hide();
          const {error} = response.data;
          this.$toasted.show(error, {
            type: "error",
          });
        });
    }
  },

  watch: {
    fieldSelected(newValue) {
      this.fields           = this.fertilityIndexes.fields_by_farm.data;
      this.payload.field_id = newValue;

      this.fertilityIndexes.indexes.forEach(item => {
        this.selectedIndex[item.id] = item.grade;
      });
    },

    dialog: {
      immediate: true,
      handler () {
        this.toggleScrollBar('hidden');
      }
    }
  },

  beforeMount() {
    this.payload                = FertilityRecords.newData();
    this.fertilityRecordService = FertilityRecords;  
  }
}
</script>

<style scoped>
  ::v-deep .col-field .v-toolbar__content {
    padding: 0;
  }

  .custom-container {
    border: 1px solid #e3e3e3;
    border-radius:5px;
    padding: 1.5rem 2rem 0.75rem 2rem;
  }

  p {
    color: #000000;
  }
</style>
