import FertilityIndexesService from './fertility-indexes.service';

class FertilityIndex {
  constructor(payload = {}) {
    Object.assign(this, {
      ...FertilityIndex.build(payload),
    });
  }

  static build(
    {
      id       = '',
      name     = '',
      phase_id = '',
      phase    = {}
    }
  ) {
    return {
      id,
      name,
      phase_id,
      phase
    };
  }

  static newData(payload) {
    return new FertilityIndex(payload);
  }

  static all() {
    return new Promise((resolve, reject) => {
      FertilityIndexesService
        .all()
        .then(
          success => {
            const fertilityIndexes = success;
            fertilityIndexes.data  = fertilityIndexes.data.map(item => this.newData(item));
            resolve(fertilityIndexes);
          },

          error => {
            reject(error);
          }
        );
    });
  }
}

export default FertilityIndex;
