<template>
  <div>
    <div class="mt-4 font-weight-bold">
      <v-icon @click="closeView()"> mdi-arrow-left </v-icon>&nbsp;
      <span style="margin-left: 0.5rem;">Voltar</span>
    </div>

    <v-toolbar class="mt-4 mb-4" elevation="0" style="background: none">
      <v-toolbar-title>
        <span class="font-weight-bold">Registro de Fertilidade</span>
        <span> / {{ getFarm }} / Detalhes</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <!-- Informações gerais -->
    <v-card class="mb-12 rounded-xl">
      <v-card-title class="yellow-border">
        <v-container class="custom-container">
          <v-row align="center">
            <v-col class="d-flex justify-start">
              <span class="font-weight-bold">Informações Gerais</span>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4">
              <p>
                <span><b>Proprietário</b></span>
              </p>

              <p>
                <span class="font-weight-light">
                  {{ fertilityRecord.producer }}
                </span>
              </p>
            </v-col>
            <v-col cols="4">
              <p>
                <span><b>Fazenda</b></span>
              </p>

              <p>
                <span class="font-weight-light">
                  {{ getFarm }}
                </span>
              </p>
            </v-col>
            <v-col cols="4">
              <p>
                <span><b>Safra</b></span>
              </p>

              <p>
                <span class="font-weight-light">
                  {{ fertilityRecord.harvest }}
                </span>
              </p>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4">
              <p>
                <span><b>AS Responsável</b></span>
              </p>

              <p>
                <span class="font-weight-light">
                  {{ getAS }}
                </span>
              </p>
            </v-col>
            <!--
            <v-col cols="4">
              <p>
                <span><b>Cultura</b></span>
              </p>

              <p>
                <span class="font-weight-light">
                  {{ fertilityRecord.culture }}
                </span>
              </p>
            </v-col>
            -->

            <v-col cols="4">
              <p>
                <span><b>Data do Registro</b></span>
              </p>

              <p>
                <span class="font-weight-light">
                  {{ fertilityRecord.recordDate | date }}
                </span>
              </p>
            </v-col>
          </v-row>
        </v-container>

        <v-container class="custom-container mt-6">
          <v-row align="center">
            <v-col class="d-flex justify-start">
              <span class="font-weight-bold">Fertilidade da Fazenda</span>
            </v-col>

            <v-spacer></v-spacer>

            <v-col class="d-flex justify-end">
              <v-icon
                v-if="authoritySlug === 'super-admin' || permissions.access_records"
                @click="editFertilityRecordFarm()"> mdi-pencil </v-icon>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="6"
              v-for="fertilityIndex in fertilityRecord.indexes"
              :key="fertilityIndex.id"
            >
              <span class="font-weight-light">
                <p :class="verifyGrade(fertilityIndex.grade)">
                  {{ fertilityIndex.name }}: {{ fertilityIndex.grade || "N/A" }}
                </p>
              </span>
            </v-col>

            <v-col cols="6">
              <p class="font-weight-bold">Índice de Fertilidade Geral: {{ fertilityRecord.recordIql }}</p>
            </v-col>
          </v-row>
        </v-container>

        <v-container class="custom-container mt-6">
          <v-row align="center">
            <v-col class="d-flex justify-start">
              <span class="font-weight-bold">Fertilidade dos Talhões</span>
            </v-col>

            <v-spacer></v-spacer>

            <!--<v-btn
              v-if="authoritySlug === 'super-admin' || permissions.access_records"
              @click="addFertilityRecordField"
              class="mb-2"
              color="primary"
              dark
            >
              + Adicionar
            </v-btn>-->
          </v-row>

          <v-row>
            <v-data-table
              style="width: 100%"
              :headers="headers"
              :items="fertilityFields"
              no-data-text="Nenhum resultado encontrado"
            >
              <template v-slot:[`item.field.size`]="{ item }">
                {{ parseFloat(item.field.size) + ' ha' }}
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                  v-if="authoritySlug === 'super-admin' || permissions.access_records"
                  small
                  @click="editItem(item)"> mdi-pencil </v-icon>

                <v-icon class="ml-4" small @click="viewItem(item)">
                  mdi-information-outline
                </v-icon>
              </template>
            </v-data-table>
          </v-row>
        </v-container>
      </v-card-title>
    </v-card>

    <fertility-record-field-add
      v-if="showFertilityRecordFieldAdd"
      :dialog="showFertilityRecordFieldAdd"
      :fields="fieldsByFarm"
      :indexes="fertilityIndexes"
      :harvest="fertilityRecord.harvest"
      :producerId="getProducerId"
      :payloadIndexes="payloadIndexes"
      @closeDialog="resetField()"
    />

    <fertility-record-field-edit
      v-if="showFertilityRecordFieldEdit"
      :dialog="showFertilityRecordFieldEdit"
      :fertilityIndexes="fertilityIndexesByField"
      :fieldSelected="fertilityIndexesByField.field_id"
      @closeDialog="resetField()"
    />

    <fertility-record-farm-edit
      v-if="showFertilityRecordFarmEdit"
      :dialog="showFertilityRecordFarmEdit"
      :fertilityRecord="fertilityRecord"
      :producerSelected="fertilityRecord.farmDetails.data.producer.id"
      :farmSelected="fertilityRecord.farmDetails.data.id"
      @closeDialog="resetFarm()"
    />
  </div>
</template>

<script>
import LoginService from "@/domain/login/login.service";
import FertilityRecords from "@/domain/records/fertility-records/fertility-records";
import Fields from "@/domain/fields/fields";
import FertilityIndexes from "@/domain/indexes/fertility-indexes/fertility-indexes";
import Contracts from "@/domain/contracts/contracts";

import FertilityRecordFieldAdd from "./FertilityRecordFieldAdd.vue";
import FertilityRecordFieldEdit from "./FertilityRecordFieldEdit.vue";
import FertilityRecordFarmEdit from "./FertilityRecordFarmEdit.vue";

import {getItem} from '@/services/local-storage.service';

export default {
  name: "FertilityRecordDetails",
  components: {
    FertilityRecordFieldAdd,
    FertilityRecordFieldEdit,
    FertilityRecordFarmEdit,
  },
  data: () => ({
    headers: [
      { text: "Nome do Talhão", value: "field.name" },
      { text: "Tamanho", value: "field.size" },
      { text: "Índice de fertilidade", value: "iql_record_field" },
      { text: "Ações", value: "actions", sortable: false },
    ],

    authority: true,
    adminId: [1, 2],

    showFertilityRecordFieldAdd: false,
    showFertilityRecordFieldEdit: false,
    showFertilityRecordFarmEdit: false,
    closeDialogFarm: false,

    fertilityRecordService: null,
    fieldService: null,
    fertilityIndexService: null,
    contractService: null,

    payload: {},
    farmId: "",
    harvest: "",
    fertilityRecord: {},
    dialog: false,
    fertilityFields: [],
    fieldsByFarm: [],
    fertilityIndexes: [],
    fertilityIndexesByField: {},
    payloadIndexes: {},

    authoritySlug: "",
    permissions: ""
  }),

  computed: {
    getAS() {
      const record      = this.fertilityRecord;
      const conditional = record.farmDetails && record.farmDetails.data;

      return conditional 
        ? record.farmDetails.data.producer.responsible_success_advisor.name 
        : '';
    },

    getFarm() {
      const farm = this.fertilityRecord.farm;

      return JSON.stringify(farm) !== JSON.stringify({})
        ? farm 
        : '';
    },

    getProducerId() {
      const record = this.fertilityRecord;
      return record.farmDetails.data.producer.id;
    },

    isViewingAdmin() {
      return this.authority;
    }
  },

  methods: {
    getUserLogged() {
      const loader = this.$loading.show();

      LoginService
      .getUserLogged()
      .then((data) => {

        if (!this.adminId.includes(data.authority_id)) {
          this.authority = false;
        }

        loader.hide();
      })
      .catch(() => {
        location.reload();
        loader.hide();
      });
    },

    loadFertilityRecord() {
      const loader = this.$loading.show();

      this.fertilityRecordService
        .get(this.farmId, this.harvest)
        .then((result) => {
          loader.hide();
          this.fertilityRecord = result;

          if (!this.closeDialogFarm && this.authoritySlug !== 'success-advisor') {
            this.createFertilityFields();
          }

          this.closeDialogFarm = false;
        })
        .catch(({ response }) => {
          loader.hide();

          const { error } = response.data;

          this.$router.push({ name: "fertility-records" });
          this.$toasted.show(error, {
            type: "error",
          });
        });
    },

    loadFertilityFields() {
      const loader = this.$loading.show();
      this.fertilityFields = [];

      this.fertilityRecordService
        .getFertilityRecordFields(this.farmId, this.harvest)
        .then((result) => {
          result.data.data.map((item) => {
            this.fertilityFields.push(item);
          });
        });

      loader.hide();
    },

    async loadFieldsAndIndexes() {
      const loader          = this.$loading.show();
      this.fieldsByFarm     = [];
      this.fertilityIndexes = [];
      this.payloadIndexes   = {};

      await this.fieldService.listByFarm(this.farmId).then((result) => {
        result.data.map((item) => {
          this.fieldsByFarm.push(item);
        });
      });

      await this.contractService
        .enabledIndexesByFertilityAndProducer(this.getProducerId)
        .then(result => {
          result.data.map(item => {
            this.fertilityIndexes.push(item);
            this.payloadIndexes[item.index.id] = null;
          });
      });

      loader.hide();
    },

    loadIndexesByField(fieldId, harvest) {
      this.fertilityIndexesByField = {};

      this.fertilityRecordService
        .getFertilityRecordByField(fieldId, harvest)
        .then((result) => {
          this.fertilityIndexesByField = result.data;
        });
    },

    async createFertilityFields() {
      const loader = this.$loading.show();

      let indexes = {};

      this.fertilityRecord.indexes.forEach(item => {
        indexes[item.index_id] = null;
      });

      const send = {
        'farm_id'     : this.fertilityRecord.farm_id,
        'harvest'     : this.fertilityRecord.harvest,
        'indexes'     : indexes,
        'producer_id' : this.fertilityRecord.producer_id
      };

      await this.fertilityRecordService
          .createFertilityFields(send)
          .then(() => {
            loader.hide();
            this.$toasted.show("Todos os talhões foram vinculados aos índices de fertilidade.", {
              type: "success",
            });
          })
          .catch(() => {
            loader.hide();
            this.$toasted.show("Não foi possível vincular todos os talhões aos índices de fertilidade.", {
              type: "error",
            });
          });

      this.loadFertilityFields();
    },

    addFertilityRecordField() {
      this.showFertilityRecordFieldAdd = true;
      this.loadFieldsAndIndexes();
    },

    viewItem(item) {
      this.$router.push({
        name: "fertility-record-fields-details",
        params: { fieldId: item.field_id, harvests: this.harvest },
      });
    },

    editItem(item) {
      this.showFertilityRecordFieldEdit = true;
      this.loadIndexesByField(item.field_id, this.harvest);
    },

    editFertilityRecordFarm() {
      this.showFertilityRecordFarmEdit = true;
    },

    resetFarm() {
      this.dialog = false;
      this.showFertilityRecordFarmAdd = false;
      this.showFertilityRecordFarmEdit = false;
      this.closeDialogFarm = true;
      this.loadFertilityRecord();
    },

    resetField() {
      this.dialog = false;
      this.showFertilityRecordFieldAdd = false;
      this.showFertilityRecordFieldEdit = false;
      this.loadFertilityFields();
    },

    closeView() {
      this.$router.push({ name: "fertility-records" });
    },

    verifyGrade(grade) {
      return !grade && "no-grade";
    },
  },

  mounted() {
    this.farmId  = this.$route.params.farmId;
    this.harvest = this.$route.params.harvest;

    this.loadFertilityRecord();

    if (this.authoritySlug === 'success-advisor') this.loadFertilityFields();
  },

  beforeMount() {
    this.getUserLogged();
    this.payload                = FertilityRecords.newData();
    this.fertilityRecordService = FertilityRecords;
    this.fieldService           = Fields;
    this.fertilityIndexService  = FertilityIndexes;
    this.contractService        = Contracts;

    this.authoritySlug = getItem('authoritySlug');
    this.permissions   = JSON.parse(getItem('permissions'));
  },
};
</script>

<style scoped>
  .custom-container {
    border: 1px solid #e3e3e3;
    border-radius: 5px;
    padding: 1.5rem 2rem 0.75rem 2rem;
  }

  p {
    color: #000000;
  }

  .no-grade {
    color: red;
    font-weight: bold;
  }

  ::v-deep .v-toolbar__content{
    padding: 0 !important;
  }
</style>
