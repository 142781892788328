<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    persistent
  >
    <v-card>
      <v-card-title class="form-modal-header">
        <v-btn color="white" text @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <span class="text-h5 white--text">Novo Registro</span>
        <v-spacer></v-spacer>
        <v-btn color="white" text @click="save"> Salvar </v-btn>
      </v-card-title>
      <v-card-text class="mt-12">
        <v-container>
          <v-row>
            <v-col cols="12" md="6" class="col-field">
              <v-toolbar elevation="0" style="background: none">
                <v-toolbar-title class="font-weight-bold">
                  Selecione um Talhão
                </v-toolbar-title>
              </v-toolbar>

              <v-row>
                <v-col cols="8">
                  <v-select
                    v-model="payload.field_id"
                    :items="fields"
                    item-text="name"
                    item-value="id"
                    no-data-text="Nenhum resultado encontrado"
                    class="
                      rounded-xl
                      mt-2
                      mr-4
                      bordered-input
                      font-weight-light
                    "
                    label="Talhão"
                    solo
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>

        <v-container>
          <v-row>
            <v-col cols="12" md="6" class="col-field">
              <v-toolbar elevation="0" style="background: none">
                <v-toolbar-title class="font-weight-bold">
                  Índices de Fertilidade
                </v-toolbar-title>
              </v-toolbar>

              <v-container class="custom-container">
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                    v-for="fertilityIndex in indexes"
                    :key="fertilityIndex.index.id"
                  >
                    <p>{{ fertilityIndex.index.name }}</p>

                    <v-select
                      :items="grades"
                      :item-value="fertilityIndex.index.id.toString()"
                      label="Selecione uma opção"
                      class="rounded-xl mt-2 bordered-input font-weight-light"
                      solo
                      @input="handleSelect(fertilityIndex.index.id, $event)"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import FertilityRecords from "@/domain/records/fertility-records/fertility-records";

export default {
  name: "FertilityRecordFieldEdit",
  props: {
    dialog: {
      type: Boolean,
      default: false
    },

    fields: {
      type: Array,
      default: () => [],
    },

    indexes: {
      type: Array,
      default: () => [],
    },

    harvest: {
      type: Number
    },

    producerId: {
      type: Number
    },

    payloadIndexes: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    payload: {},
    fertilityRecordService: null,
    grades: ['6.0', '6.5', '7.0', '7.5', '8.0', '8.5', '9.0', '9.5', '10.0'],
  }),

  methods: {
    handleSelect(indexId, indexGrade) {
      this.payload.indexes[indexId] = indexGrade;
    },

    toggleScrollBar(param) {
      let html = document.querySelector('html');
      html.setAttribute("style", `overflow: ${param};`);
    },

    close() {
      this.toggleScrollBar('auto');
      this.$emit("closeDialog");
    },

    save() {
      const loader = this.$loading.show();

      this.payload.harvest     = this.harvest;
      this.payload.producer_id = this.producerId;

      this.fertilityRecordService
        .create(this.payload)
        .then(() => {
          loader.hide();
          this.close();

          this.$toasted.show("Registro de fertilidade cadastrado com sucesso.", {
              type: "success",
            }
          );
        })
        .catch(({response}) => {
          loader.hide();
          const {error} = response.data;
          this.$toasted.show(error, {
            type: "error",
          });
        });
    },
  },

  watch: {
    dialog: {
      immediate: true,
      handler () {
        this.toggleScrollBar('hidden');
      }
    }
  },

  beforeMount() {
    this.payload                = FertilityRecords.newData();
    this.payload.indexes        = this.payloadIndexes;
    this.fertilityRecordService = FertilityRecords;
  }
}
</script>

<style scoped>
  .custom-container {
    border: 1px solid #e3e3e3;
    border-radius: 5px;
    padding: 1.5rem 2rem 0.75rem 2rem;
  }

  p {
    color: #000000;
  }
</style>
