import http from "@/services/http.service";

const apiUrl = "/fertility-indexes";

class FertilityIndexesService {
  static all() {
    return http.get(`${apiUrl}`);
  }
}

export default FertilityIndexesService;
